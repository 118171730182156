import { Hyperlink } from '@components/Hyperlink';
import classes from './index.module.scss';

export const GitHubLogo: React.FC<{
  href: string
  newTab?: boolean
}> = (props) => {
  const {
    href,
    newTab
  } = props;

  return (
    <Hyperlink
      href={href}
      newTab={newTab}
      className={classes.github}
      aria-label="GitHub source code"
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        className={classes.svg}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3521 0C7.0952 0 0.397949 6.86573 0.397949 15.3303C0.397949 22.102 4.70988 27.8391 10.5814 29.9083C11.3154 30.0023 11.5906 29.5321 11.5906 29.1558C11.5906 28.7796 11.5906 27.8391 11.5906 26.5224C7.46217 27.4629 6.54474 24.4533 6.54474 24.4533C5.90254 22.6663 4.89336 22.1961 4.89336 22.1961C3.51722 21.2556 4.98511 21.2556 4.98511 21.2556C6.453 21.3496 7.27868 22.8544 7.27868 22.8544C8.65483 25.2057 10.7649 24.5473 11.5906 24.1711C11.6824 23.1366 12.1411 22.4782 12.508 22.102C9.20529 21.7258 5.71905 20.4091 5.71905 14.4839C5.71905 12.791 6.26951 11.4742 7.27868 10.3456C7.18694 10.0635 6.63648 8.4646 7.46217 6.39548C7.46217 6.39548 8.74657 6.01927 11.5906 7.99434C12.7833 7.61814 14.0677 7.52409 15.3521 7.52409C16.6365 7.52409 17.9209 7.71219 19.1135 7.99434C21.9576 6.01927 23.242 6.39548 23.242 6.39548C24.0677 8.4646 23.5172 10.0635 23.4255 10.4397C24.3429 11.4742 24.9851 12.885 24.9851 14.5779C24.9851 20.5031 21.4989 21.7258 18.1961 22.102C18.7466 22.5723 19.2053 23.5128 19.2053 24.9235C19.2053 26.9927 19.2053 28.5915 19.2053 29.1558C19.2053 29.5321 19.4805 30.0023 20.2145 29.9083C26.1778 27.8391 30.3979 22.102 30.3979 15.3303C30.3062 6.86573 23.609 0 15.3521 0Z"
          className={classes.fill}
        >
        </path>
      </svg>
    </Hyperlink >
  )
}
