import React from 'react';
import classes from '../index.module.scss';

export const CopyIcon: React.FC<{
  color?: string
  size?: string
  bold?: boolean
}> = (props) => {
  const {
    color,
    size,
    bold
  } = props;

  return (
    <svg
      className={[
        classes.icon,
        color && classes[color],
        size && classes[size],
        bold && classes.bold
      ].filter(Boolean).join(' ')}
      viewBox="0 0 24 24"
      height="100%"
      width="100%"
    >
      <path
        className={classes.fill}
        d="M15 20H5V7c0-.55-.45-1-1-1s-1 .45-1 1v13c0 1.1.9 2 2 2h10c.55 0 1-.45 1-1s-.45-1-1-1zm5-4V4c0-1.1-.9-2-2-2H9c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h9c1.1 0 2-.9 2-2zm-2 0H9V4h9v12z"
      />
    </svg>
  );
};
